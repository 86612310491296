$(document).ready(function() {

    $('#show-menu').on('click', function(){
        var button = this;

        $('#main-menu')
            .slideToggle('fast', function(){
                $(button).toggleClass('active', function(state){
                    console.log(state);
                });
                $("html, body").animate({scrollTop: 0}, 100);
            });

        //$("#main-menu").toggleClass('hidden');
    });

    $("#main-menu-collapse")
        .on('show.bs.collapse', function(item){

            /** Collapse all other */
            $(this).find('div.in').not(item.target)
                .removeClass('in'); // collapse other

            /** Remove all angle-down */
            $.each( $(this).find('i'), function(){
                $(this).removeClass('fa-angle-down').addClass('fa fa-angle-right');
            });

            /** Add angle right on selection */
            $(item.target).prev('a').find('i').removeClass('fa-angle-right').addClass('fa fa-angle-down');

            /** Remove all active class*/
            $('#main-menu-collapse').find('a.active').removeClass('active');

        })
        .on('hide.bs.collapse', function(item){
            /** Remove all angle-down */
            $.each( $(this).find('i'), function(){
                $(this).removeClass('fa-angle-down').addClass('fa fa-angle-right');
            });

            //$( $('a[href="#'+$(item.target).attr("id")+'"]')).removeClass('active');

            /** Remove all active class*/
            $('#main-menu-collapse').find('a.active').removeClass('active');


        });

    /* FAQ Collapse */
    $('a.faq-toggle')
        .on('click', function(){
            console.log( $(this) );
            $("#faq > div.collapse").not($(this)).removeClass('in');
        });

    /*** MAP **/
    if($('#map').length > 0){
        var map,
            latitude = parseFloat($('#map').attr('data-latitude')),
            longitude = parseFloat($('#map').attr('data-longitude')),
            url = $('#map').attr('data-url'),
            title = $('#map').attr('data-title'),
            bounds = [];

        var styles = [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}];

        map = new GMaps({
            div: '#map',
            zoom: 16,
            lat: latitude,
            lng: longitude
        });
       map.addStyle({
            styledMapName:"Styled Map",
            styles: styles,
            mapTypeId: "map_style"
        });
        map.setStyle("map_style");
        map.addMarker({
            lat: latitude,
            lng: longitude,
            title: title,
            /*icon : {
                size: new google.maps.Size(75,75),
                url: '/img/marker75.png'
            },*/
            click : function(e){
                window.open(url);
            }
        });
    }

});


(function($, document, window, viewport){

    var carouselHeight = function () {
        var navbarH = $("nav.navbar").height(),
            ctaH = $('div.cta_home').height();

        $('#carousel').height( $(window).height() - navbarH - ctaH + 10 );
    }

    /** Body cols EqualHeight */
    var colHeight = function(){

        if( $('.page-left').height() < $('.page-right').height() ){
            $('.page-left').height( $('.page-right').height()+100 );
            $('.page-left').find('img').height('100%');
            console.log('Left < Right');
            console.log( $('.page-left').height() );
        }
    }

    /** body margin bottom for sticky footer*/
    var footerHeight = function(){
        var footerHeight = $('#footer').height()+50;
        $('body').animate({
            'marginBottom': footerHeight+'px'
        },100);
        console.log(footerHeight);
    }

    $(document).ready(function() {

        console.log(viewport.current());

        switch(viewport.current()){

            case "xs":
            case "sm":
                break;

            default:
                colHeight();
                carouselHeight();
                break;
        }

        footerHeight();

    });

    $(window).resize(
        viewport.changed(function(){

            switch(viewport.current()){
                case "xs":
                case "sm":
                    break;
                default:
                    colHeight();
                    carouselHeight();
                    break;
            }

            footerHeight();
        })
    );

})(jQuery, document, window, ResponsiveBootstrapToolkit);